import * as React from "react"

import Layout from "../components/layout"
import { Link } from "gatsby"
import TarjetaServicio from "../components/tarjetaServicio"
import Warehouses from "../components/warehouses"
import ReactDOM from 'react-dom';
import Modal from 'react-modal';

import logo from "../images/logo.png"
import popImg from "../images/popup-removebg-preview.png"

const customStyles = {
	content: {
	  top: '50%',
	  left: '50%',
	  right: 'auto',
	  bottom: 'auto',
	  marginRight: '-50%',
	  transform: 'translate(-50%, -50%)',
	//   padding: '40px',
	//   borderRadius: '20px',
	//   backgroundColor: '#014880',
	  backgroundColor: 'transparent',
	  color: "#FFF",
	  zIndex: 9999,
	//   borderColor: '#014880',
	border: 0
	},
  };
  

const IndexPage = () => {
  const [modalIsOpen, setIsOpen] = React.useState(true);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }

  return(
  <Layout
	seccion="index"
	title="Somos expertos"
	subTitle="en logística internacional"
	linkTo="servicios">

		<div id="servicios">
			<div className="row">
				<div className="col-sm-3">
					<Link to="/importacion">
						<TarjetaServicio  
							key="imp"
							idServicio="imp"
							serviceName="IMPO"
							serviceDesc="IMPORTACION"
							servicios={ ['Servicio Courier Internacional','Servicio No Limits','Servicio Carga Aérea'] }
						/>
					</Link>
				</div>
				<div className="col-sm-3">
					<Link to="/exportacion">
						<TarjetaServicio  
							key="exp"
							idServicio="exp"
							serviceName="EXPO"
							serviceDesc="EXPORTACION"
							servicios={ ['Servicio Courier Internacional','Servicio Exporta Simple','Servicio Carga Aérea'] }
						/>
					</Link>
				</div>
				<div className="col-sm-3">
					<Link to="/maritimo">
						<TarjetaServicio  
							key="mar"
							idServicio="mar"
							serviceName="MAR"
							serviceDesc="MARÍTIMO"
							servicios={ ['Servicio Carga marítima exportación','Servicio Carga marítima importación'] }
						/>
					</Link>
				</div>
				<div className="col-sm-3">
						<Link to="/nacional">
							<TarjetaServicio  
								key="nac"
								idServicio="nac"
								serviceName="NAC"
								serviceDesc="NACIONAL"
								servicios={ ['Raico N@cional'] }
							/>
						</Link>
				</div>
			</div>
		</div>
		<div id="experienciaContainer">
			<div className="row">
				<div id="expCol" className="col-sm-6">
					<div id="expTxt" className="">
						<div id="expArrow" className="col-sm-1">
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
</svg>
						</div>

							<div id="expDesc" className="col-sm-11"
												data-aos='fade-up'
												data-aos-duration="600" data-aos-once="true">
								<div id="txtDesc"><span>Más de 30 años<br/>de experiencia</span></div>
								<p>En <strong>Raiconet</strong> trabajamos día a día para brindarle a nuestros<br/>
								clientes soluciones logísticas profesionales y creativas a la<br/>
								medida de sus necesidades.</p>
								<Link to="/empresa">
									<div id="expFoot">
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
		  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
		</svg>
										CONOCER MÁS SOBRE RAICONET
									</div>
								</Link>
							</div>
					</div>
				</div>
				<div id="expImg" className="col-sm-6 index" 
								  data-sal="zoom-in"
								  data-sal-delay="0"
								  data-sal-duration="900">
					<div></div>
				</div>
			</div>
		</div>
		<Warehouses />	
		<Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      	>
			<div className="closeModal" onClick={closeModal}>
			  <svg 
			  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
				<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
				<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
				</svg>
			</div>  
				<a href="https://www.raiconews.com/" target="_blank">
					<img src={popImg} alt="Raiconews" title="Raiconews"
						style={{
							width: '100%'
						}} 
					/>
				</a>

			  {/* <h2>Estimados clientes:</h2>
			  <p
			  	style={{ fontSize: '1.2em' }}
			  >A partir del mes de junio el nuevo número de teléfono es:</p>
				<br/>
			  <h4 style={{ 'fontSize': '3em'}}>
				<div className="phoneIcon" style={{ 'marginRight': '15px' }}>
					<svg style={{ 'width': '50px', 'height': '50px', ' borderRadius': '60px' }} 
					xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16">
						<path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
						</svg>
				  </div>
					0810 122 7771 </h4>			  

			  <img src={logo} alt="Raiconet - Logística mundial" title="Raiconet - Logística mundial"
			 	style={{
					width: '30%',
					marginLeft: 'auto',
					display: 'block'
				}} 
			  /> */}
		</Modal>
  </Layout>
)}

export default IndexPage
